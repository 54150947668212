/* You can add global styles to this file, and also import other style files */

input.mui-like,
select.mui-like {
    border-color: #c5c5c5;
    border-style: solid;
    border-radius: 8px;
    border-width: 1px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding: 8px;
}

input.mui-like.inactive,
select.mui-like.inactive {
    color: #c5c5c5;
}

input.mui-like.inactive::placeholder {
    color: #c5c5c5;
}

input.mui-like.inactive::-moz-placeholder {
    color: #c5c5c5;
}

input.mui-like.inactive::-webkit-input-placeholder{
    color: #c5c5c5;
}

input.mui-like.inactive::-ms-input-placeholder {
    color: #c5c5c5;
}